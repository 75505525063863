@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Audiowide&family=Barlow&family=Exo&family=Public+Sans&display=swap);
footer {
    padding-top: 1rem;
}

footer .logo {
    width: 200px;
    font-size: 35px !important;
    color: #FFFFFF !important;
}

footer .nav-link {
    color: rgba(255, 255, 255, 0.8);
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    letter-spacing: 0.1em;
    line-height: 32px;
}

.nav-link.active,
.nav-link:hover,
.nav-link:focus {
    color: #E8D33E !important;
}

footer .list-group-item {
    background: transparent;
    border: none;
    padding-top: 0;
    padding-bottom: 0;
}

.copyright {
    color: rgba(255, 255, 255, 0.3);
    font-size: 15px;
    letter-spacing: 0.1em;
    line-height: 32px;
    padding: 1rem;
    margin: 0;
    text-align: center;
    font-family: 'Public Sans';
}

@media (max-width: 768px) {
    footer.justify-content-between {
        flex-wrap: wrap;
        justify-content: center !important;
    }

    footer.justify-content-between>* {
        display: flex;
        width: 100%;
        text-align: center;
        align-items: center;
        justify-content: center;
    }
}
.navbar{
    margin-bottom: 2em;
    margin-top: -1.5em;
}

.navbar .navbar-nav {
    width: 85%;
}

.navbar .navbar-toggler {
    margin-left: auto;
}

.navbar .nav-button {
    display: flex;
}

.navbar .navbar-nav .nav-item .nav-link {
    color: rgba(255, 255, 255, 0.8);
    font-size: 18px;
}

.navbar .navbar-nav .nav-item .nav-link:hover {
    color: #0a58ca;
}

@media (max-width: 991px) {
    .navbar .nav-button {
        display: none;
    }
}

.navbar-brand {
    font-size: 35px !important;
    color: #E8D33E !important;
}
html,body,.app-wrapper,#root{min-height:100%}.app-wrapper{font-family:"Poppins",sans-serif;background-image:url(/static/media/Background.d27c00dc.svg);background-size:cover}.audio-wide{font-family:"Audiowide",cursive}.barlow{font-family:"Barlow",sans-serif}.exo{font-family:"Exo",sans-serif}.public-sans{font-family:"Public Sans",sans-serif}
