.navbar{
    margin-bottom: 2em;
    margin-top: -1.5em;
}

.navbar .navbar-nav {
    width: 85%;
}

.navbar .navbar-toggler {
    margin-left: auto;
}

.navbar .nav-button {
    display: flex;
}

.navbar .navbar-nav .nav-item .nav-link {
    color: rgba(255, 255, 255, 0.8);
    font-size: 18px;
}

.navbar .navbar-nav .nav-item .nav-link:hover {
    color: #0a58ca;
}

@media (max-width: 991px) {
    .navbar .nav-button {
        display: none;
    }
}

.navbar-brand {
    font-size: 35px !important;
    color: #E8D33E !important;
}