@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Audiowide&family=Barlow&family=Exo&family=Public+Sans&display=swap");

html,
body,
.app-wrapper,
#root {
  min-height: 100%;
}

.app-wrapper {
  font-family: "Poppins", sans-serif;
  background-image: url("assets/img/Background.svg");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.audio-wide {
  font-family: 'Audiowide', cursive;
}

.barlow {
  font-family: 'Barlow', sans-serif;
}

.exo {
  font-family: 'Exo', sans-serif;
}

.public-sans {
  font-family: 'Public Sans', sans-serif;
}